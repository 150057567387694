import React, { useEffect, useState } from 'react';
import ServiceItem from './ServiceItem';
import './Services.css';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const ServiceList = () => {
    const [services, setServices] = useState([]);

    useEffect(() => {
        fetch('/assets/data/services.json')
            .then(response => response.json())
            .then(data => setServices(data));
    }, []);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <section id="servicios" className="services py-5">
            <div className="container">
                <h2 className="text-center mb-4">Servicios</h2>
                <Slider {...settings}>
                    {services.map(service => (
                        <ServiceItem key={service.title} {...service} />
                    ))}
                </Slider>
            </div>
        </section>
    );
};

export default ServiceList;
