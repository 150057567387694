// FAQList.js
import React, { useState, useEffect } from 'react';
import './FAQ.css';

const FAQList = () => {
    const [faqs, setFaqs] = useState([]);

    useEffect(() => {
        fetch('/assets/data/faq.json')
            .then(response => response.json())
            .then(data => setFaqs(data));
    }, []);

    return (
        <section id="faq" className="faq py-5">
            <div className="container">
                <h2 className="text-center mb-4">Preguntas Frecuentes</h2>
                <div className="accordion w-100" id="faqAccordion">
                    {faqs.map((faq, index) => (
                        <div className="accordion-item" key={index}>
                            <h2 className="accordion-header" id={`heading${index}`}>
                                <button 
                                    className="accordion-button collapsed" 
                                    type="button" 
                                    data-bs-toggle="collapse" 
                                    data-bs-target={`#collapse${index}`} 
                                    aria-expanded="false" 
                                    aria-controls={`collapse${index}`}>
                                    {faq.question}
                                </button>
                            </h2>
                            <div 
                                id={`collapse${index}`} 
                                className="accordion-collapse collapse" 
                                aria-labelledby={`heading${index}`} 
                                data-bs-parent="#faqAccordion">
                                <div className="accordion-body">
                                    {faq.answer}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default FAQList;
