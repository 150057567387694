import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import './Services.css';

const resolveImage = (icon) => require(`../../assets/images/${icon}`);

const ServiceItem = ({ title, description, icon, duration, upcoming }) => {
    return (
        <div className="service-item">
            <div className={`card ${upcoming ? 'upcoming' : ''}`}>
                <img src={resolveImage(icon)} className="card-img-top service-icon" alt={title} />
                <div className="card-body">
                    <h5 className="card-title">{title}</h5>
                    <p className="card-text">{description}</p>
                    <p className="card-duration">
                        <FontAwesomeIcon icon={faClock} /> {duration}
                    </p>
                    <a
                        href={!upcoming ? `https://wa.me/5218111835907?text=Hola%20quiero%20hacer%20una%20cita%20para%20un%20masaje%20${title}%20a%20domicilio` : '#'}
                        className="btn cta-button service-cta"
                        style={upcoming ? { pointerEvents: 'none', opacity: 0.5 } : {}}
                    >
                        Haz una cita
                    </a>
                </div>
                {upcoming && <div className="overlay">Próximamente</div>}
            </div>
        </div>
    );
};

export default ServiceItem;
