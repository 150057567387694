import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Carousel } from 'bootstrap'; // Importar directamente desde Bootstrap
import testimonialsData from '../../assets/data/testimonials.json';
import './Testimonials.css';

const TestimonialList = () => {
    const [testimonials, setTestimonials] = useState([]);

    useEffect(() => {
        setTestimonials(testimonialsData);
    }, []);

    useEffect(() => {
        const carouselElement = document.querySelector('#testimonialCarousel');
        const bootstrapCarousel = new Carousel(carouselElement, {
            interval: 3000,
            ride: 'carousel'
        });

        return () => {
            bootstrapCarousel.dispose();
        };
    }, []);

    return (
        <section id="testimonios" className="testimonials py-5 bg-light">
            <div className="container">
                <h2 className="text-center mb-4">Testimonios</h2>
                <div id="testimonialCarousel" className="carousel slide" data-bs-ride="carousel">
                    <div className="carousel-inner">
                        {testimonials.map((testimonial, index) => (
                            <div key={testimonial.name} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
                                <div className="testimonial-item">
                                    <div className="card mb-4">
                                        <div className="card-body">
                                            <p className="card-text">"{testimonial.text}"</p>
                                            <h5 className="card-title text-right">- {testimonial.name}</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#testimonialCarousel" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#testimonialCarousel" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
            </div>
        </section>
    );
};

export default TestimonialList;
