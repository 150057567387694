import React from 'react';
import './CTA.css';

const CTA = () => {
    return (
        <section id="home" className="cta-section position-relative text-white text-center">
            <video autoPlay muted loop className="cta-video">
                <source src="assets/videos/portada.mp4" type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            <div className="cta-overlay">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-end text-md-end text-center">
                            <img src="assets/images/wellnes_logo.webp" alt="Wellness Logo" className="cta-logo" />
                        </div>
                    </div>
                    <div className="row justify-content-center align-items-center">
                        <div className="col-md-8 text-center">
                            <h1 className="cta-title display-5 fw-bold text-white">Revitaliza Tu Cuerpo y Mente</h1>
                            <p className="cta-text lead mb-4">
                                Transformamos la tensión en tranquilidad a través de masajes especializados que restauran, rejuvenecen y revitalizan tu cuerpo y mente.
                            </p>
                            <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">
                                <a href="https://wa.me/5218111835907?text=Hola%20quiero%20informes%para%un%20masaje%20a%20domicilio" className="btn btn-primary btn-lg cta-button px-4">Agenda tu cita</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default CTA;
