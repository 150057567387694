import React, { useEffect } from 'react';
import './Header.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { useLocation, Link } from 'react-router-dom';

const Header = () => {
    const location = useLocation();
    useEffect(() => {
        const handleScroll = () => {
            const navbar = document.querySelector('.navbar');
            if (window.scrollY > 50) {
                navbar.classList.add('scrolled');
            } else {
                navbar.classList.remove('scrolled');
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <header className="header">
            <nav className="navbar navbar-expand-lg navbar-dark fixed-top">
                <a className="navbar-brand" href="#home"></a>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav ml-auto">
                        {location.pathname === '/store' ? (
                            <li className="nav-item">
                                <Link className="nav-link home-link" to="/">Home</Link>
                            </li>
                        ) : (
                            <>
                                <li className="nav-item"><a className="nav-link home-link" href="#home">Inicio</a></li>
                                <li className="nav-item"><a className="nav-link" href="#servicios">Servicios</a></li>
                                <li className="nav-item"><a className="nav-link" href="#testimonios">Testimonios</a></li>
                                <li className="nav-item"><a className="nav-link" href="#about-me">Nosotros</a></li>
                                <li className="nav-item"><a className="nav-link" href="#faq">Preguntas frecuentes</a></li>
                            </>
                        )}
                    </ul>
                </div>
            </nav>
        </header>
    );
};

export default Header;