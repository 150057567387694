// AboutMe.js
import React from 'react';
import './AboutMe.css';

const AboutMe = () => {
    return (
        <section id="about-me" className="p-4 text-center w-100 rounded-top">
            <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <img src="assets/images/about1.avif" className="d-block w-100" alt="About Me 1" />
                    </div>
                    <div className="carousel-item">
                        <img src="assets/images/about2.jpeg" className="d-block w-100" alt="About Me 2" />
                    </div>
                    <div className="carousel-item">
                        <img src="assets/images/about3.jpg" className="d-block w-100" alt="About Me 3" />
                    </div>
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
        </section>
    );
};

export default AboutMe;
