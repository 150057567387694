import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header/Header';
import CTA from './components/CTA/CTA';
import Testimonials from './components/Testimonials/TestimonialList';
import ServiceList from './components/Services/ServiceList';
import Footer from './components/Footer/Footer';
import AboutMe from './components/AboutMe/AboutMe';
import FAQ from './components/FAQ/FAQList'

function App() {
  return (
    <Router>
      <div>
        <Header />
        <Routes>
          <Route path="/" element={<div><CTA /> <ServiceList /> <Testimonials /> <AboutMe /> <FAQ /></div>} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
