import React from 'react';
import './Footer.css';

const Footer = () => {
    return (
        <footer className="footer bg-dark text-white text-center py-3">
            <p>&copy; 2024 Wellness Zen Studio</p>
        </footer>
    );
};

export default Footer;
